* {
  padding: 0;
  margin: 0;
}

.main-container {
  padding: 0;
  margin: 0;
  width: 100%;
  background: transparent url("./images/web-bg.png") 0% 0% no-repeat;
  background-size: cover;
  opacity: 1;
  color: white;
  overflow-x: none;
}

.navbar {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.logowrapper {
  position: absolute;
  top: 10px;
  left: 20px;
  width: 10rem;
  height: auto;
  cursor: pointer;
}

.logowrapper img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 1;
}

.site-link {
  position: absolute;
  top: 20px;
  right: 10px;
  font-family: "Lato", sans-serif;
  text-align: right;
  font: normal normal normal 18px/18px Lato;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;
}

.section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  position: relative;
  height: auto;
}

.mainsection {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.site-link img {
  display: none;
}
.site-link p {
  cursor: pointer;
}
.bottomsection {
  margin-top: -53px;
}
.videobox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.videoplayer {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.btnbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sharebtnbox {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.sharebtnbox p {
  text-align: left;
  font: normal normal bold 16px/16px Lato;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.downloadbtnbox {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.downloadbtnbox p {
  text-align: left;
  font: normal normal bold 14px/14px Lato;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.rightsection {
  width: 40vw !important;
  height: 17rem;
  background: #ffffff;
  padding: 20px;
}

.content-box {
  width: 100%;
  max-height: 100%;
  display: flex;
  gap: 12px;
}

.digicertwrapper {
  min-width: 16vw;
  height: 100%;
  overflow: hidden;
}

.digicertwrapper img {
  width: 100%;
  height: 17rem;
  object-fit: fill;
}

.content-res {
  width: 17rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  box-sizing: border-box;
}

.content-res #title {
  color: #e7162d;
  text-align: left;
  font: normal normal normal 28px/24px Sophisticated Slims Tight;
  letter-spacing: 0.36px;
  color: #e7162d;
  opacity: 1;
}

.content-res #intropara {
  text-align: left;
  font: normal normal 300 16px/18px Lato;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
}

.content-res #formtitle {
  text-align: left;
  font: normal normal 300 16px/20px Lato;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
}

.formbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.formbox #inptpincode {
  width: 88%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e7162d;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 15px;
  font: normal normal bold 14px/20px Lato;
}
.videowrapper{
  width: 34.6rem;
}
.signupbtn {
  width: 100%;
  background: #e7162d 0% 0% no-repeat padding-box;
  border: 1px solid #e7162d;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 25px;
  cursor: pointer;
  color: #ffffff;
  font: normal normal bold 14px/20px Lato;
  letter-spacing: 0.56px;
  color: #eff2f1;
  opacity: 0.91;
  margin-top: 0px;
}
.addressWrapper{
  overflow: scroll;
  scroll-behavior: smooth;
}
.addressWrapper::-webkit-scrollbar{
 width: 5px;
}
.addressWrapper::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 5px rgb(105, 105, 105); 
  border-radius: 10px;
}
.selectfield{
  /* border:1px solid black; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}


    #city {
    width: 100%;
    padding: 10px 25px;
    }

.selectfield select option:hover {
  background-color: red;
  color: white; /* Set text color to white */

}

.selectfield span{
  color: black;
}

.content-res span{
  color: #858585;
  
}

/* for mobile screen */
@media only screen and (max-width: 768px) {

  .downloadbtnbox{
    cursor: none;
  }
#intropara{
  margin-bottom: 10px;
}
  .sharebtnbox{
    cursor: none;
  }
  .site-link p {
    display: none;
  }
  .content-res{
    overflow: scroll;
    z-index: 100;
  }
  .addressWrapper::-webkit-scrollbar{
    width: 5px;
   }
   .addressWrapper::-webkit-scrollbar-thumb{
    box-shadow: inset 0 0 5px rgb(105, 105, 105); 
    border-radius: 10px;
  }
  .site-link img {
    display: block;
    cursor: pointer;
  }
  .mainsection {
    flex-direction: column;
  }
  .videowrapper {
    width: 22rem !important;
    display: flex;
    align-items: center;
  }
  .leftsection {
    width: 100vw;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .videobox {
    align-items: center;
  }
}
.termstext {
  position: absolute;
  text-align: center;
  left: 40%;
  /* bottom: -12px; */
  bottom: 10%;
  cursor: pointer;
  text-shadow: 2px 2px 1px #000000;

}
.content-box #mtitle {
  display: none;
}
@media screen and (max-width: 1030px) {
  .preist-pic {
    position: relative;
    left: 5rem;
  }
  .priest-label {
    position: relative;
    right: 7.5rem;
  }
}
.preist-pic {
  width: 12rem;
  position: relative;
  left: 12rem;
  top: 1rem;
  max-height: 12rem;
}
.ceo-pic {
  width: 100%;
  height: 90%;
  position: relative;
  top: 14%;
}
.director-pic {
  width: 96%;
  height: 90%;
  position: relative;
  top: 14%;
}
.termstexts{
  position: absolute;
  left: 40%;
  bottom: 10%;
  z-index: 2;
  text-shadow: 2px 2px 1px #000000;
  cursor: pointer;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .preist-pic {
    height: 10rem;
    position: relative;
    left: -1rem;
    width: 10rem;
    top: 3.5rem;
  }
  .main-container{
    min-height: 100vh;
  }
  .image-section {
   margin-top: 2rem;
   position: relative;
   top: 0px;
  }
  .termstext {
    position: absolute;
    bottom: 1rem;
    z-index: 2;
    text-shadow: 2px 2px 1px #000000;
    cursor: pointer;
  }
  .mainsection {
    flex-direction: column;
    align-items: center;
  }

  .btnbox {
    padding: 0 8px;
    display: flex;
    gap: 4rem;
    justify-content: center;
  }

  .videoplayer {
    width: 100% !important;
    height: 100% !important;
  }

    .sharebtnbox p{
      width: 3rem;
    }
  .rightsection {
    /* margin: 0 auto; */
    margin-left: 10px;
    width: 90vw !important;
    min-height: 66vh !important;
    background-color: transparent !important;
    padding: 20px 0px;
  }

  .content-box {
    width: 96%;
    flex-direction: column;
    align-items: center;
    background-color: white;
    min-height: 38rem;
  }

  .digicertwrapper {
    width: 12rem;
    min-height: 17rem;
    overflow: hidden;
  }
  .digicertwrapper img {
    min-height: 100%;
    min-width: 100%;
  }

  .content-res {
    width: 88%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .content-box #mtitle {
    font: normal normal normal 32px/28px Sophisticated Slims Tight;
    display: inline-block;
    color: #e7162d;
    text-align: left;
    letter-spacing: 0.36px;
    color: #e7162d;
    opacity: 1;
    padding: 10px 0px;
  }

  .content-res #title {
    font: normal normal normal 24px/21px Sophisticated Slims Tight;
    display: none;
  }

  .content-res #formtitle {
    font: normal normal 300 14px/16px Lato;
    margin-top: 8px;
  }

  .formbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }

  .formbox #inptpincode {
    width: 80%;
  }

  .formbox #signupbtn {
    width: 92.5%;
  }
  .ceo-label {
    display: none;
  }
  .ceo-pic {
    width: 10rem;
    height: 11rem;
    position: relative;
    top: 3.6rem;
    right: 1rem;
  }
  .director-pic {
    width: 10rem;
    height: 11rem;
    position: relative;
    top: 3.6rem;
    right: 5rem;
  }

  .priest-label {
    display: none;
  }
  .termstexts{
    position: absolute;
    left: 25%;
    z-index: 2;
    text-shadow: 2px 2px 1px #000000;
  }
  .director-label {
    display: none;
  }
  .termstext {
    position: relative;
    bottom: -66%;
    left: 0rem;
    width: 100vw;
    z-index: 2;
    text-shadow: 2px 2px 1px #000000;
    cursor: pointer;
    border: 1px solid;
 

  }
}
@media screen and (min-width: 769px) and (max-width: 1250px) {
  .videowrapper {
    width: 30.6rem;
  }
  .content-res {
    gap: 5px;
  }
  .termstext {
    z-index: 2;
    cursor: pointer;
  
    position: absolute;
    bottom: -12%;
  
  }
}
@media screen and (min-width:1025px) and (max-width:1250) {
  .termstext{
    position: absolute;
    top: 0px;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1600px) {

}
.image-section {
  display: flex;
  overflow: hidden;
  padding-bottom: 0px;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  bottom: 10px;
  align-items: baseline;
}
.direcror-section {
  width: 12rem;
  position: relative;
  height: 14rem;
  right: 3%;
  display: flex;
}
.ceo-section {
  display: flex;
  width: 12rem;
  height: 14rem;
}

.priest-section {
  display: flex;
}

.ceo-label {
  position: relative;
  top: 80%;
  width: 10rem;
  height: 16%;
  right: 11rem;
  z-index: 2;
}
.priest-label {
  position: relative;
  top: 8.5rem;
  right: 0.5rem;
  width: 12rem;
  height: 18%;
}
.director-label {
  position: relative;
  top: 80%;
  right: 11rem;
  width: 12rem;
  height: 16%;
}

.left-img-section {
  display: flex;
  width: 67%;
}
@media screen and (max-width:1024px) {
.videowrapper{
  width: 40rem;
}
.mainsection{
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 20px;
}
.rightsection{
 
  min-width: 65vw;
  min-height: 18.5rem;
}
  body{
    overflow-y: none !important;
  }
  .digicertwrapper img{
    height: 18rem;
    width: 12rem;
  }
  .content-box{
justify-content: space-around;

  }
  .termstext{
    position: absolute;
    bottom: -22%;
  }
}
@media screen and (max-width:376px) {

.image-section{
margin-top: 10rem;
}
.videowrapper{
  width: 20rem;
}

}