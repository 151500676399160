/* Apply styles for larger screens */
.terms-and-conditions {
    max-width: 100vw; /* Set a maximum width for the content */
    margin: 0 auto; /* Center the content horizontally */
    padding: 25px 50px; /* Add some padding around the content */
    overflow-y: scroll !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap:10px;
    background-image: url('./images/web-bg.png');
    color: white;
  }
  
    .terms-and-conditions::-webkit-scrollbar {
      display: none;
    }
    .terms-and-conditions__section h3{
      margin-top: 5px;
    }


      /* Apply styles for medium-sized screens (tablets) */
      @media screen and (max-width: 768px) {
        .terms-and-conditions {
          padding: 20px;
          font-size: 14px; /* Reduce font size for smaller screens */
        }
      }
  
  /* Apply styles for small screens (mobile devices) */
  @media screen and (max-width: 480px) {
    .terms-and-conditions {
      font-size: 12px; /* Further reduce font size for smaller screens */
      padding: 20px;
    }
  }